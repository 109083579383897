import { Component } from '@angular/core';
import { stripeKeys } from '.env/privateKeys';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'flavorfactory-web';
  constructor() {
    (window as any).Stripe.setPublishableKey(stripeKeys.publishableKey);
  }
}
