import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];  route: ActivatedRouteSnapshot;
  constructor(private _route:Router){}

  canActivate()
  {
    if(!localStorage.getItem('flavour-factorywebtoken'))
    {
      this._route.navigate(['login'])
      return false;
    }
    else
    {
      return true;
    }
  }
  
}
