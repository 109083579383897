import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth/auth.gaurd';
import { LogoutGuard } from './core/auth/logout-gaurd';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: './content/common/login/login.module#LoginModule',
    canActivate: [LogoutGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: './content/common/forgot-password/forgot-password.module#ForgotPasswordModule',
    canActivate: [LogoutGuard]
  },
  {
    path: 'sign-up',
    loadChildren: './content/common/sign-up/sign-up.module#SignUpModule',
    canActivate: [LogoutGuard]
  },
  {
    path: 'reset-password',
    loadChildren: './content/common/reset-password/reset-password.module#ResetPasswordModule',
    canActivate: [LogoutGuard]
  },
  {
    path: 'new-password',
    loadChildren: './content/common/new-password/new-password.module#NewPasswordModule',
    canActivate: [LogoutGuard]
  },
  {
    path: 'pages',
    loadChildren: './content/pages/pages.module#PagesModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'select-subscription',
    loadChildren: './content/select-subscription/select-subscription.module#SelectSubscriptionModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'privacy-policy',
    loadChildren: './content/common/privacy-policy/privacy-policy.module#PrivacyPolicyModule'
  },
  {
    path: 'unsubscribe',
    loadChildren: './content/common/privacy-policy/privacy-policy.module#PrivacyPolicyModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
